<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="shadowCard">
    	<v-card-title primary-title class="text-subtitle-1">
    	  Promociones alumnos
    	</v-card-title>
    	<v-card-text class="black--text">

    		Llena los siguientes datos por favor

    		<br/>
    		<br/>

    		Encuesta:
    		<br/>

    		<v-row>
    			<v-col cols="12" lg="4" md="6">
		    		<label>Elige escuela</label>
		    		<v-radio-group
				      v-model="escuela"
				      row
				    >
				      <v-radio
				        label="INBI"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="FAST"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>

    		<v-row>
    			<v-col cols="12" lg="4" md="6">
		    		<label>Elige una edad</label>
		    		<v-radio-group
				      v-model="edad"
				      row
				    >
				      <v-radio
				        label="9 a 12 años"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="13 años en adelantes"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>

    		<v-row>
    			<v-col cols="12" lg="4" md="6">
    				<label>Elige la modalidad</label>
		    		<v-radio-group
				      v-model="modalidad"
				      row
				    >
				      <v-radio
				        label="Presencial"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Online"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>

    		<v-row>
    			<v-col cols="12" lg="4" md="6">
    				<label>Elige un horario</label>
		    		<v-radio-group
				      v-model="horario"
				      column
				    >
				      <v-radio
				        label="Entre semana"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Sabatino Matutitno"
				        :value="2"
				      ></v-radio>
				      <v-radio
				        label="Sabatino Vespertino"
				        :value="3"
				      ></v-radio>
				      <v-radio
				        label="Dominical"
				        :value="4"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>

    		<v-row>
    			<v-col cols="12" lg="4" md="6">
    				<label>Elige una opción</label>
		    		<v-radio-group
				      v-model="hermanos"
				      row
				    >
				      <v-radio
				        label="Único"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="Hermanos"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>


	      <v-row dense>
	      	<v-col cols="12">
	        	<v-img :src="url"></v-img>
	      	</v-col>
	      </v-row>
    	</v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
	import axios from 'axios';

  import { mapGetters, mapActions }    from "vuex";

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	edad: null,
    	modalidad: null,
    	horario: null,
    	hermanos: null,

    	promociones: [],
    	escuela: null
    }),

    created( ){

    	this.initialize( )
    },

    methods:{

    	initialize( ){
				this.cargar = true;
	      this.promociones = [];
	      this.$http.get("promociones.imagenes").then((response) => {
	        this.promociones = response.data
	        console.log( this.promociones )
	        this.cargar = false;
	      }).catch((error) => {
	        this.validarError(error);
	      }).finally(() => {
	        this.cargar = false;
	      });
    	}

    },

    computed:{
      ...mapGetters('login',['getEscuela']),

    	url () {

    		let promociones = this.promociones.filter( el => el.escuela == this.escuela )

    		console.log( this.getEscuela )

    		let api = 'https://escuelakpi.club/kpi/imagenes-promociones/'
        
        let link = ''

        if( this.edad && this.modalidad && this.horario && this.hermanos ){

        	let promo = promociones.find( el => el.edad == this.edad && el.modalidad == this.modalidad && el.horario == this.horario && el.hermanos == this.hermanos )

        	if( promo ){
        		link = api + promo.imagen
        	}

        }

        return link
    	},
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
  
</style>